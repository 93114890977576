<template>
    <AssinaturaPedidoIndex :numeroDocumento="numeroDocumento" />
</template>

<script lang="js">
    import AssinaturaPedidoIndex from '../../components/assinaturaEletronica/assinaturaPedido.vue'
    
    
    export default {
        name: 'assinaturaPedido',
        components: { AssinaturaPedidoIndex },
        data() {
            return {
                numeroDocumento: null,
            };
        },
        created() {
            this.validarRotas();
        },
        updated() {
            this.validarRotas();
        },
        methods: {
            validarRotas() {
                
                const const_numeroDocumento = this.$route.params.numeroDocumento;

                console.log(`constIdAssinatura: `, const_numeroDocumento);

                this.numeroDocumento = const_numeroDocumento;
                
            },
            
        },
    }

</script>