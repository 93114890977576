import axios from "axios";
import PortalCooperadoUtils from "../utilities/PortalCooperadoUtils";

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/assinatura-eletronica`;

class AssinaturaEletronica {
  consultarEnvelopeId(idAssinatura) {
    return axios.get(`${API_BASE_URL}${RESOURCE_V1}/buscar-envelopes-id/${idAssinatura}`);
  }

  consultarEnvelopeNumeroDocumento(numeroDocumento) {
    return axios.get(`${API_BASE_URL}${RESOURCE_V1}/buscar-envelope-numero-documento/${numeroDocumento}`);
  }

  downloadDocumento(idEnvelope) {
    return axios.get(`${API_BASE_URL}${RESOURCE_V1}/download-pdf/${idEnvelope}`, { responseType: "blob" });
  }

  async pegarIp() {
    try {
      const response = await fetch("https://ipinfo.io/json");
      const data = await response.json();
      const ip = data?.ip || "";
      return ip;
    } catch (error) {
      console.log("Erro ao capturar o IP:", error);
      return "";
    }
  }

  assinar(assinatura) {
    console.log("Payload metodo assinar: ", assinatura);
    return axios.put(`${API_BASE_URL}${RESOURCE_V1}/assinar`, assinatura);
  }
}

export default new AssinaturaEletronica();
